import { type BaseError } from "@/components/ErrorComponent";
import { cn } from "@/helpers/cn";
import { getPublicConfig } from "@/helpers/getPublicConfig";
import { getTransformedUrl } from "@/services/cloudinary/getTransformedUrl";
import { type Prettify } from "@/types/prettify";
import { type VariantProps, cva } from "class-variance-authority";
import Image, { type ImageProps } from "next/image";
import { BiErrorAlt } from "react-icons/bi";

const {
  service: {
    cloudinary: { domain },
  },
} = getPublicConfig();

const imageVariants = cva(" relative ", {
  variants: {
    size: {
      bottomTabAvatar: ["w-7", "h-7"],
      "3xs": ["w-6", "h-6"],
      "2xs": ["w-9", "h-9"],
      xs: ["w-12", "h-12"],
      sm: ["w-16", "h-16"],
      suggestedPrincessAvatar: ["w-20", "h-20"],
      md: ["w-24", "h-24"],
      lg: ["w-32", "h-32"],
      xl: ["w-48", "h-48"],
      "2xl": ["w-64", "h-64"],
      "3xl": ["w-96", "h-96"],
      avatar: ["w-20", "h-20", "md:w-48", "md:h-48"],
    },
    shape: {
      roundedFull: ["rounded-full"],
      rounded: ["rounded-lg"],
      square: ["rounded-none"],
    },
  },
  compoundVariants: [
    {
      shape: "rounded",
      size: ["3xs", "2xs"],
      className: "rounded",
    },
  ],
  defaultVariants: {
    size: "md",
    shape: "rounded",
  },
});

const imageSizeInPx = {
  bottomTabAvatar: 28,
  "3xs": 24,
  "2xs": 36,
  xs: 48,
  sm: 64,
  suggestedPrincessAvatar: 80,
  md: 96,
  lg: 128,
  xl: 192,
  "2xl": 256,
  "3xl": 384,
  avatar: 224,
};

type ImageSize = NonNullable<VariantProps<typeof imageVariants>["size"]>;
type ImageShape = NonNullable<VariantProps<typeof imageVariants>["shape"]>;

type ImageDisplayVariants = Parameters<typeof imageVariants>;

const image = (...variants: ImageDisplayVariants) =>
  cn("relative", imageVariants(...variants));

export type ImageDisplayProps = Prettify<
  {
    size: ImageSize;
    shape: ImageShape;
    src: string;
    className?: string;
    containerClassName?: string;
    alt: string;
    unoptimised?: boolean;
  } & Omit<ImageProps, "className" | "src" | "loading" | "alt">
>;

type LoadingImageSkeletonProps = Pick<
  ImageDisplayProps,
  "size" | "shape" | "className"
>;

type ImageErrorComponent = { error: BaseError } & Pick<
  ImageDisplayProps,
  "size" | "shape" | "className"
>;

const ImageErrorComponent = ({
  size,
  shape,
  error,
  className,
}: ImageErrorComponent) => {
  return (
    <div
      className={image({
        size,
        shape,
        className: cn(
          "tooltip  flex items-center justify-center bg-red-200",
          className,
        ),
      })}
      data-tip={error.message}
    >
      <BiErrorAlt className="h-1/2 w-1/2 text-red-500 " />
    </div>
  );
};

export const LoadingImageSkeleton = ({
  size,
  shape,
  className,
}: LoadingImageSkeletonProps) => {
  return (
    <div
      className={image({
        size,
        shape,
        className: cn("animate-pulse bg-gray-300", className),
      })}
    ></div>
  );
};

export const ImageDisplay = ({
  size,
  shape,
  alt,
  src: _src,
  className,
  containerClassName,
  ...props
}: ImageDisplayProps) => {
  const sizePx = imageSizeInPx[size];
  const isCloudinary = _src.includes(domain);
  const src = isCloudinary
    ? getTransformedUrl(_src, sizePx * 3, sizePx * 3)
    : _src;

  return (
    <div
      className={image({
        size,
        shape,
        className: containerClassName,
      })}
    >
      <Image
        {...props}
        src={src}
        fill
        className={cn(
          "rounded-full object-cover object-center",
          image({ shape }),
          className,
        )}
        alt={alt}
        unoptimized={isCloudinary}
      />
    </div>
  );
};
