export type ErrorMessage =
  | "AUTH__LOGIN_REQUIRED"
  | "FORB__USER_BANNED"
  | "FORB__QUOTA_REQUIRED"
  | "FORB__ALBUM_NO_PERMISSION_TO_PRIVATE_ALBUM"
  | "BUSI__USER_NOT_FOUND";

// Add translation keys to translation files in web & app
export const translationKeyByErrorMessage: Record<ErrorMessage, string> = {
  AUTH__LOGIN_REQUIRED: "msg:trpc_err.auth__login_required",
  FORB__USER_BANNED: "msg:trpc_err.forb__user_banned",
  FORB__QUOTA_REQUIRED: "msg:trpc_err.forb__quota_required",
  BUSI__USER_NOT_FOUND: "msg:trpc_err.busi__user_not_found",
  FORB__ALBUM_NO_PERMISSION_TO_PRIVATE_ALBUM:
    "msg:trpc_err.forb__album_no_permission_to_private_album",
};
